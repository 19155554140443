// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import FictionImage1 from '../images/fictiongimg1.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import ghostWritingImage from '../images/Ghost Writing.png';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Pattern Books",
    image: ghostWritingImage, // add the appropriate image variable
    description: "Our children's book ghostwriters specialize in creating delightful pattern books that engage young readers with rhythmic text and repetitive phrases, making learning fun and interactive!"
  },
  {
    title: "Concept Books",
    image: designingImage, // add the appropriate image variable
    description: "Need a way to introduce essential ideas to little ones? We craft concept books that simplify complex topics, like colors, shapes, and numbers, using charming illustrations and easy-to-follow narratives."
  },
  {
    title: "Alphabet Books",
    image: bookEditingImage, // add the appropriate image variable
    description: "Help children discover the alphabet in a captivating way! Our talented team can create imaginative alphabet books that pair each letter with whimsical illustrations and engaging stories, ensuring learning is a joyful adventure."
  }
];

// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
     Helping You Light Up 
     <br />
     Children’s Lives
    </>
  ),
 
};
// const description = "We follow a proper book cover designing process to ensure the best final product for our clients.";

const steps = [
  {
    image: designprocess1,
    alt: "Create A Draft",
    title: "Create A Draft",
    description: "We start by selecting the best candidates for your project based on the skills your book requires. They dive deep into your concept, researching everything they can find. Then, we send you an outline for your approval to make sure we are on the right track."
  },
  {
    image: designprocess2,
    alt: "Editing And Proofreading",
    title: "Editing And Proofreading",
    description: "Editing happens in real time as we write. Once the draft is complete, our editors will carefully review it multiple times, ensuring everything is polished, proofread, and ready to shine."
  },
  {
    image: designprocess3,
    alt: "Producing The Final Draft",
    title: "Producing The Final Draft",
    description: "Once you give the green light on the manuscript, we will format it to meet publishing standards. Our team adds a professional touch with beautiful fonts and vibrant illustrations that will delight kids and spark their imaginations."
  },
  {
    image: designprocess4,
    alt: "Publishing & Promotion",
    title: "Publishing & Promotion",
    description: "After you approve the final version, complete with text and illustrations, we will use our resources to help us in publishing and printing a children’s book and roll out a custom promotional strategy to get your story out into the world!"
  }
];



// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "How does AMZ Book Publishings ensure professionalism in its services?",
    answer: "At AMZ Book Publishings, professionalism is at the heart of what we do. We stick to strict deadlines and keep you in the loop every step of the way, from our first chat to the final delivery. No matter the size or complexity of your project, our talented ghostwriters provide a smooth and professional experience for every client."
  },
  {
    question: "Can you help me become a published author?",
    answer: "Absolutely! Our ghostwriting services are designed to help you craft an engaging children’s story that’s ready to impress publishers and literary agents. With the guidance of our skilled ghostwriters, who have a track record of helping authors get published, you will have a great shot at seeing your book in print."
  },
  {
    question: "How can I start writing a children's book?",
    answer: "Kick things off by choosing a genre and identifying the age group you want to target. Think about themes and subjects that resonate with young readers. Then, create a captivating story filled with relatable characters and a meaningful lesson or point to share."
  },
  {
    question: "What makes a children's book important?",
    answer: "Children's books play a crucial role in shaping a child's development. They not only boost cognitive and verbal skills but also spark imagination and creativity. Moreover, these stories help kids develop emotional intelligence, fostering empathy and understanding as they learn to express their feelings."
  },
  {
    question: "Are your services confidential?",
    answer: "You bet! We adhere to professional industry standards to ensure your work remains confidential. Rest assured, any excerpts from your book will not be used elsewhere without your consent. Your story is yours, and we treat it that way!"
  }
];



// ******************************** End FAQ Section ******************************

const ChildrensBook = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      <Helmet>
        <title>Children Book Ghostwriting Services by Top Ghostwriters</title>
        <meta name="description" content="Need Children book ghostwriter? We offer professional Children book ghostwriting services on affordable price. Meet well experienced book and ebook writers." />
        <link rel="canonical" href="https://amzbookpublishing.net/childrens-book-ghostwriting" />

      </Helmet>
      {/* -------------------Start Banner section---------------------- */}
      <Header />
      <BreadCrumb
        title="Children’s Book Ghostwriting"
        desc={
          <>
       At AMZ Book Publishings, we know how to weave magic into words, creating delightful tales that leave a lasting impression on kids and inspire their imaginations.
            {/* <br/>  */}
            Let us help you bring your story to life!
          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}

{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
  
    
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '40px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Children’s Book"
    subHeading=" Ghostwriting"
    description={
        <>
        At AMZ Book Publishings, our skilled children’s book ghostwriters know exactly what it takes to create enchanting stories for young readers. They tap into their creativity to craft timeless tales that resonate with innocent minds, ensuring every word is both engaging and easy to understand.
           <br /><br />
           Our writing services are grounded in key principles that prioritize an unforgettable reading experience tailored specifically for kids. 
           <br /><br />
           With imaginative language and a distinctive style, we make sure to captivate their hearts and transport them to magical worlds they will love exploring!
        </>
    }
    imageSrc={FictionImage1}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Skilled  </>}
        subHeading={<>
        Children’s <br/>
        Book</>}
        heading2="Ghostwriters"
        paragraph="We are in close contact with top publishers and literary agents in the children’s literature scene, so you can count on us to guide you through every step of the writing and publishing journey."
        services={services}
      />
      </div>


{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}


<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />

<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Why Choose Us"
    subHeading=""
    description={
      <>
          <ul>
              <li>
                  <b>Expertise in Children’s Literature:</b> Our children's book ghostwriters have a deep understanding of what captivates young minds. We tailor stories that not only entertain but also align with the developmental needs of children.
              </li>
              <li>
                  <strong>Personalized Approach:</strong> We believe in collaboration, working hand-in-hand with you to transform your ideas into a vibrant narrative. Your vision guides our storytelling, ensuring that it resonates with young readers.
              </li>
              <li>
                  <strong>Creative Storytelling:</strong> Our writers are masters of imaginative storytelling, creating magical worlds that spark curiosity and wonder. We craft narratives that children can escape into, fostering a love for reading.
              </li>
              <li>
                  <strong>Thorough Research & Planning:</strong> Our meticulous research process helps us understand current trends and themes in children's literature. We create detailed outlines that ensure every story flows smoothly and engages the reader from start to finish.
              </li>
          </ul>
      </>
  }
  
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>


</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
    
  );
};
export default ChildrensBook;


